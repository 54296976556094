<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Account overview</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Hosts (dedicated)</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-host', params: { accountId: this.$route.params.accountId } }">See all hosts</router-link>
                    </p>
                    <p class="text-overline mb-0 mt-8">Domains</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">See all domains</router-link>
                    </p>
                    <!-- TODO: websites here is really a placeholder for a BY-CATEGORY VIEW of any of the apps... see all websites, all issue trackers, all marketing apps, etc. -->
                    <p class="text-overline mb-0 mt-8">Websites</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-website', params: { accountId: this.$route.params.accountId } }">See all websites</router-link>
                    </p>
                    <p class="text-overline mb-0 mt-8">Users</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId } }">See all users</router-link>
                    </p>
                    <p class="text-overline mb-0 mt-8">Volumes</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-volume', params: { accountId: this.$route.params.accountId } }">See all storage volumes</router-link>
                    </p>
                    <!-- <AccountViewList :list="list"/> -->
                    <!-- <RealmList @selected="onSelectRealm" create class="mt-12"/> -->
                    <p class="text-overline mb-0 mt-8">Other</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">How to delete this account</router-link>
                    </p>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import UserList from '@/components/account-dashboard/UserList.vue';

export default {
    components: {
        // UserList,
    },
    data: () => ({
        account: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        name() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
